const Musics = ({musics,setActive,active}) => {
    return (
        <div className="musics" id="musics">
            {musics.map((item,index) => (
                <span key={index} className={active?.index === index ? 'active' : ''} onClick={() => {setActive({
                    index,
                    music:musics[index]
                })
                }}>{index + 1}.{item.name}</span>
            ))}
        </div>
    )
}

export default Musics