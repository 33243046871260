import {useEffect, useState} from "react";
import LogoAz from './images/logo2 (1).png'
import ListItem from "./ListItem";
import Baku from './images/Baku.jpg'
import Fond from './images/logo2 (2).png'
import Musics from "./Musics";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import TofiqQuliyev from './images/tofiq-quliyev.jpg'
import text from './text.json'

import GozeldirVetenin
    from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/1. GÖZƏLDİR VƏTƏNİN YAŞIL DÜZLƏRİ.mp3'
import VetenimDedim from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/2. VƏTƏNİM DEDİM.mp3'
import Neftci from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/3. NEFTÇİ MAHNISI.mp3'
import CanVetenim from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/4. CAN VƏTƏNİM.mp3'
import SeviremSeni from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/5. SEVİRƏM SƏNİ, AZƏRBAYCAN.mp3'
import AzerbaycanaGelsin from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/6. AZƏRBAYCANA GƏLSİN.mp3'
import EySevincim from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/7. EY SEVİNCİM, BAKI.mp3'
import BuEllerde from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/8. BU ELLƏRDƏ.mp3'
import AxtaranTapar from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/9. AXTARAN TAPAR.mp3'
import SirvanGozeli from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/10. ŞİRVAN GÖZƏLİ.mp3'
import AbseronMayaki from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/11. ABŞERON MAYAKI.mp3'
import Naxcivanim from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/12. NAXÇIVANIM.mp3'
import AnaKur from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/13. ANA KÜR.mp3'
import Badamli from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/14. BADAMLI.mp3'
import Bakiliyam from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/15. BAKILIYAM MƏN.mp3'
import Ziyafet from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/16. ZİYAFƏT.mp3'
import VetenHaqqinda from './Audio/1. Mənim Azərbaycanım (Vətən haqqında mahnılar)/17. VƏTƏN HAQQINDA MAHNI.mp3'

import SenedeQalmaz from './Audio/2. Məhəbbət haqqında mahnılar/1. SƏNƏ DƏ QALMAZ.mp3'
import SevdiyimQiz from './Audio/2. Məhəbbət haqqında mahnılar/2. SEVDİYİM QIZ.mp3'
import CavanamMen from './Audio/2. Məhəbbət haqqında mahnılar/3. CAVANAM MƏN.mp3'
import MenKovrelmisem from './Audio/2. Məhəbbət haqqında mahnılar/4. MƏN KÖVRƏLMİŞƏM.mp3'
import GelBeri from './Audio/2. Məhəbbət haqqında mahnılar/5. GƏL BƏRİ.mp3'
import IlkBahar from './Audio/2. Məhəbbət haqqında mahnılar/6. İLK BAHAR.mp3'
import NarGulu from './Audio/2. Məhəbbət haqqında mahnılar/7. NAR GÜLÜ.mp3'
import BaharGelir from './Audio/2. Məhəbbət haqqında mahnılar/8. BAHAR GƏLİR.mp3'
import KipirklerinKolgesi from './Audio/2. Məhəbbət haqqında mahnılar/9. KİPRİKLƏRİN KÖLGƏSİ.mp3'
import YolAyricinda from './Audio/2. Məhəbbət haqqında mahnılar/10. YOL AYRICINDA.mp3'
import MenimQizim from './Audio/2. Məhəbbət haqqında mahnılar/11. MƏNİM QIZIM.mp3'
import SenMenim from './Audio/2. Məhəbbət haqqında mahnılar/12. SƏN MƏNİM, MƏN SƏNİN.mp3'
import SeviremSeni2 from './Audio/2. Məhəbbət haqqında mahnılar/13. SEVİRƏM SƏNİ.mp3'
import PerisanOldugumAnlar from './Audio/2. Məhəbbət haqqında mahnılar/14. PƏRİŞAN OLDUĞUM ANLAR.mp3'
import MehebbetQocalmaz from './Audio/2. Məhəbbət haqqında mahnılar/15. MƏHƏBBƏT QOCALMAZ.mp3'
import AxsamGorusleri from './Audio/2. Məhəbbət haqqında mahnılar/16. AXŞAM GÖRÜŞLƏRİ.mp3'
import BexteverOldum from './Audio/2. Məhəbbət haqqında mahnılar/17. BƏXTƏVƏR OLDUM.mp3'
import Etibar from './Audio/2. Məhəbbət haqqında mahnılar/18. ETİBAR.mp3'
import TezGel from './Audio/2. Məhəbbət haqqında mahnılar/19. TEZ GƏL.mp3'
import SeherNegmesi from './Audio/2. Məhəbbət haqqında mahnılar/20. SƏHƏR NƏĞMƏSİ.mp3'
import ToyMahnisi from './Audio/2. Məhəbbət haqqında mahnılar/21. TOY MAHNISI.mp3'

import Dobroe from './Audio/3. Rus dilində mahnılar/1. ДОБРОЕ УТРО, МОЙ АЗЕРБАЙДЖАН.mp3'
import OSEN from './Audio/3. Rus dilində mahnılar/2. ОСЕНЬ.mp3'
import Devicey from './Audio/3. Rus dilində mahnılar/3. У ДЕВИЧЬЕЙ БАШНИ.mp3'
import Sravnit from './Audio/3. Rus dilində mahnılar/4. С КЕМ СРАВНИТЬ.mp3'
import Aksiya from './Audio/3. Rus dilində mahnılar/5. КОГДА ЦВЕТЕТ АКАЦИЯ.mp3'
import Dobriy from './Audio/3. Rus dilində mahnılar/6. В ДОБРЫЙ ПУТЬ.mp3'
import Nocnaya from './Audio/3. Rus dilində mahnılar/7. НОЧНАЯ СЕРЕНАДА.mp3'
import Vseqda from './Audio/3. Rus dilində mahnılar/8. ВСЕГДА С ТОБОЙ.mp3'
import Zarya from './Audio/3. Rus dilində mahnılar/9. ЗАРЯ НАД КАСПИЕМ.mp3'
import Zuleyxa from './Audio/3. Rus dilində mahnılar/10. ЗУЛЕЙХА.mp3'
import Tobiy from './Audio/3. Rus dilində mahnılar/11. С ТОБОЙ.mp3'
import Lyubli from './Audio/3. Rus dilində mahnılar/12. Я ЛЮБЛЮ ТЕБЯ.mp3'
import Vetvistoe from './Audio/3. Rus dilində mahnılar/13. ЗОЛОТО ВЕТВИСТОЕ.mp3'
import Azerbaycan from './Audio/3. Rus dilində mahnılar/14. АЗЕРБАЙДЖАН.mp3'
import Nado from './Audio/3. Rus dilində mahnılar/15. НЕ НАДО.mp3'
import Lireceskaya from './Audio/3. Rus dilində mahnılar/16. ЛИРИЧЕСКАЯ ПЕСНЯ.mp3'
import Scastliviy from './Audio/3. Rus dilində mahnılar/17. СЧАСТЛИВЫЙ НАПЕВ.mp3'
import Rodimis from './Audio/3. Rus dilində mahnılar/18. РОДИМЫЙ КРАЙ-АЗЕРБАЙДЖАН.mp3'
import BakuSong from './Audio/3. Rus dilində mahnılar/19. МОЙ БАКУ.mp3'
import Devuski from './Audio/3. Rus dilində mahnılar/20. ДЕВУШКИ БАКУ.mp3'

import SevgiValsi from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/1. SEVGİ VALSI - BƏXTİYAR (1955).mp3'
import Azerbaycan_az from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/2. AZƏRBAYCAN - BƏXTİYAR (1955).mp3'
import Zibeyde from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/3. ZİBEYDƏ - BƏXTİYAR (1955).mp3'
import BakiNegmesi from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/4. BAKI NƏĞMƏSİ - BƏXTİYAR (1955).mp3'
import DostluqMahnisi from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/5. DOSTLUQ MAHNISI - BƏXTİYAR (1955).mp3'
import Sevgilim from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/6. SEVGİLİM - BƏXTİYAR (1955).mp3'
import Tanqo from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/7. TANQO - BƏXTİYAR (1955).mp3'
import QizilUzuk from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/8. QIZIL ÜZÜK - BƏXTİYAR (1955).mp3'
import Aruzlar from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/9. ARZULAR - GÖRÜŞ (1955).mp3'
import AxsamMahnisi from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/10. AXŞAM MAHNISI - GÖRÜŞ (1955).mp3'
import QizlarinMahnisi
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/11. QIZLARIN MAHNISI - QIZMAR GÜNƏŞ ALTINDA (1957).mp3'
import Laylay from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/12. LAYLAY - ÖGEY ANA (1958).mp3'
import Kendimiz from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/13. KƏNDİMİZ - ÖGEY ANA (1958).mp3'
import Bagcamiz from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/14. BAĞÇAMIZ - ÖGEY ANA (1958).mp3'
import EzizSuret
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/15. ƏZİZ SURƏT - ONU BAĞIŞLAMAQ OLARMI (1959).mp3'
import QemginMahni
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/16. QƏMGİN MAHNI - ONU BAĞIŞLAMAQ OLARMI (1959).mp3'
import SevincMahnisi
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/17. SEVİNC MAHNISI - ONU BAĞIŞLAMAQ OLARMI (1959).mp3'
import BaharMahnisi
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/18. BAHAR MAHNISI - ONU BAĞIŞLAMAQ OLARMI (1959).mp3'
import BakiHaqqindaMahni
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/19. BAKI HAQQINDA MAHNI - TELEFONÇU QIZ (1962).mp3'
import Gelmedi from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/20. GƏLMƏDİ - TELEFONÇU QIZ (1962).mp3'
import LirikMahni from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/21. LİRİK MAHNI - MÖCÜZƏLƏR ADASI (1963).mp3'
import CosDenizim from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/22. COŞ DƏNİZİM - MÖCÜZƏLƏR ADASI (1963).mp3'
import Vokaliriz from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/23. VOKALİZ - MÖCÜZƏLƏR ADASI (1963).mp3'
import YoxYoxYox from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/24. YOX, YOX, YOX - SƏN NİYƏ SUSURSAN (1966).mp3'
import Nargile from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/25. NARGİLƏ - SƏN NİYƏ SUSURSAN (1966).mp3'
import KimsenSen from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/26. KİMSƏN SƏN - O QIZI TAPIN (1970).mp3'
import DervislerinMahnisi
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/27. DƏRVİŞLƏRİN MAHNISI - NƏSİMİ (1973).mp3'
import SoylediYoxYox from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/28. SÖYLƏDİ YOX, YOX - NƏSİMİ (1973).mp3'
import DervisParisiPatladir
    from './Audio/4. Kinofilmlərdən mahnılar (1-ci hissə)/29. MAHNI - DƏRVİŞ PARİSİ PARTLADIR (1976).mp3'

import KababcininMahnisi
    from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/1. KABABÇININ MAHNISI - ŞİR EVDƏN GETDİ (1977).mp3'
import QaynanaLirikMahni from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/2. LİRİK MAHNI - QAYNANA (1978).mp3'
import MehebbetCagi from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/3. MƏHƏBBƏT ÇIRAĞI - QAYNANA (1978).mp3'
import MehribanAileyik from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/4. MEHRİBAN AİLƏYİK - QAYNANA (1978).mp3'
import GelinimQaynana from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/5. GƏLİNİM - QAYNANA (1978).mp3'
import TezeGelin from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/6. TƏZƏ GƏLİN - QAYNANA (1978).mp3'
import NegmekarTorpaq from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/7. LAYLAY - NƏĞMƏKAR TORPAQ (1981).mp3'
import QizElleri from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/8. QIZ ƏLLƏRİ - NƏĞMƏKAR TORPAQ (1981).mp3'
import Yollar from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/9. YOLLAR - ƏZABLI YOLLAR (1982).mp3'
import LaylayEzabliYollar from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/10. LAYLAY - ƏZABLI YOLLAR (1982).mp3'
import Qardaslar from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/11. QARDAŞLAR - ƏZABLI YOLLAR (1982).mp3'
import Doyus1 from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/12. DÖYÜŞ 1 - ƏZABLI YOLLAR (1982).mp3'
import Doyus2 from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/13. DÖYÜŞ 2 - ƏZABLI YOLLAR (1982).mp3'
import Final from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/14. FİNAL - ƏZABLI YOLLAR (1982).mp3'
import Deti
    from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/15. ДЕТИ - МУЗЫКА ПЛАНЕТЫ - MUSİQİ MÜƏLLİMİ (1983).mp3'
import List from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/16. ЛИСТ ЧИНАРА - MUSİQİ MÜƏLLİMİ (1983).mp3'
import SenMen from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/17. ТЫ И Я - MUSİQİ MÜƏLLİMİ (1983).mp3'
import Sefiqa from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/18. ШАФИГА - MUSİQİ MÜƏLLİMİ (1983).mp3'
import Faytoncu from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/19. FAYTONÇU - MƏN BAKIDA YAŞAYIRAM (1986).mp3'
import Darixma from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/20. DARIXMA - XÜSUSİ VƏZİYYƏT (1986).mp3'
import VaxtindaGeler
    from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/21. VAXTINDA GƏLƏR - XÜSUSİ VƏZİYYƏT (1986).mp3'
import FinalXusisiEziyyet from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/22. FİNAL - XÜSUSİ VƏZİYYƏT (1986).mp3'
import NiyeGedirsen
    from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/23. NİYƏ GEDİRSƏN - XÜSUSİ VƏZİYYƏT (1986).mp3'
import AmanAllah
    from './Audio/5. Kinofilmlərdən mahnılar (2-ci hissə)/24. AMAN ALLAH, SƏN KÖMƏYİM OL - NAKƏS (1991).mp3'

import Bextiyar from './Audio/6. Kinofilmlərdən instrumental musiqi/1. PROLOQ - BƏXTİYAR (1955).mp3'
import Gorus from './Audio/6. Kinofilmlərdən instrumental musiqi/2. PROLOQ - GÖRÜŞ (1955).mp3'
import MusiqiLovhesi1
    from './Audio/6. Kinofilmlərdən instrumental musiqi/3. MUSİQİ LÖVHƏSİ 1 - TELEFONÇU QIZ (1962).mp3'
import MusiqiLovhesi2
    from './Audio/6. Kinofilmlərdən instrumental musiqi/4. MUSİQİ LÖVHƏSİ 2 - TELEFONÇU QIZ (1962).mp3'
import MusiqiLovhesi3
    from './Audio/6. Kinofilmlərdən instrumental musiqi/5. MUSİQİ LÖVHƏSİ 3 - TELEFONÇU QIZ (1962).mp3'
import MusiqiLovhesi4
    from './Audio/6. Kinofilmlərdən instrumental musiqi/6. MUSİQİ LÖVHƏSİ 4 - TELEFONÇU QIZ (1962).mp3'
import Hisse1 from './Audio/6. Kinofilmlərdən instrumental musiqi/7. 1-Cİ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964).mp3'
import Hisse2 from './Audio/6. Kinofilmlərdən instrumental musiqi/8. 2-Cİ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964).mp3'
import Hisse3 from './Audio/6. Kinofilmlərdən instrumental musiqi/9. 3-CÜ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964).mp3'
import Hisse4 from './Audio/6. Kinofilmlərdən instrumental musiqi/10. 4-CÜ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964).mp3'
import Hisse5 from './Audio/6. Kinofilmlərdən instrumental musiqi/11. 5-Cİ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964).mp3'
import Hisse6 from './Audio/6. Kinofilmlərdən instrumental musiqi/12. 6-CI HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964).mp3'
import Yol from './Audio/6. Kinofilmlərdən instrumental musiqi/13. YOL - SƏN NİYƏ SUSURSAN (1966).mp3'
import Noktyrun from './Audio/6. Kinofilmlərdən instrumental musiqi/14. NOKTYURN - ELMİN İŞIĞI (1969).mp3'
import Dusunceler from './Audio/6. Kinofilmlərdən instrumental musiqi/15. DÜŞÜNCƏLƏR - O QIZI TAPIN (1970).mp3'
import ProloqDervis from './Audio/6. Kinofilmlərdən instrumental musiqi/16. PROLOQ - DƏRVİŞ PARİSİ PARTLADIR (1976).mp3'
import ProloqQaynana from './Audio/6. Kinofilmlərdən instrumental musiqi/17. PROLOQ - QAYNANA (1978).mp3'
import Xatireler from './Audio/6. Kinofilmlərdən instrumental musiqi/18. XATİRƏLƏR - ƏLAVƏ İZ (1981).mp3'
import Nostalji from './Audio/6. Kinofilmlərdən instrumental musiqi/19. NOSTALJİ - ƏZABLI YOLLAR (1982).mp3'
import Parafraz from './Audio/6. Kinofilmlərdən instrumental musiqi/20. PARAFRAZ - MUSİQİ MÜƏLLİMİ (1983).mp3'
import Hekayeler from './Audio/6. Kinofilmlərdən instrumental musiqi/21. HEYKƏLLƏR - MƏN BAKIDA YAŞAYIRAM (1986).mp3'
import Intizar from './Audio/6. Kinofilmlərdən instrumental musiqi/22. İNTİZAR - SÜRƏYYA (1987).mp3'
import Sevgi from './Audio/6. Kinofilmlərdən instrumental musiqi/23. SEVGİ - SÜRƏYYA (1987).mp3'
import Stilistik from './Audio/6. Kinofilmlərdən instrumental musiqi/24. STİLİSTİK VARİASİYALAR - ƏLAVƏ İZ (1991).mp3'
import Ufuq from './Audio/6. Kinofilmlərdən instrumental musiqi/25. ÜFÜQ - ƏZABLI YOLLAR (1991).mp3'

import DogmaMelodiylar
    from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/1. DOĞMA MELODİYALAR (Estrada miniatürləri).mp3'
import MusiqiLovheleri
    from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/2. MUSİQİ LÖVHƏLƏRİ (Estrada miniatürləri).mp3'
import BakiGecesi from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/3. BAKI GECƏSİ (Estrada miniatürləri).mp3'
import Meydanda from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/4. MEYDANDA (Estrada miniatürləri).mp3'
import BaharMelodiylari
    from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/5. BAHAR MELODİYALARI (Estrada miniatürləri).mp3'
import EstradaMiniaturu1 from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/6. ESTRADA MİNİATÜRÜ 1.mp3'
import EstradaMiniaturu2 from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/7. ESTRADA MİNİATÜRÜ 2.mp3'
import Sheher from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/8. ŞƏHƏR (Estrada miniatürləri).mp3'
import Poppuri from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/9. POPURRİ (Estrada miniatürləri).mp3'
import CimerlikValsi from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/10. ÇİMƏRLİK VALSI (Rəqs ritmi).mp3'
import AzerbaycanReqsi from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/11. AZƏRBAYCAN RƏQSİ (Rəqs ritmi).mp3'
import Vals2 from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/12. VALS 2 (Rəqs ritmi).mp3'
import SozsuzMahni from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/13. SÖZSÜZ MAHNI (Rəqs ritmi).mp3'
import MezeliReqs from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/14. MƏZƏLİ RƏQS (Rəqs ritmi).mp3'
import Qaytagi from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/15. QAYTAĞI 1 (Rəqs ritmi).mp3'
import Vals3 from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/16. VALS 3 (Rəqs ritmi).mp3'
import ReqsRitm from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/17. RƏQS (Rəqs ritmi).mp3'
import Naxcivani from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/18. NAXÇIVANİ (Rəqs ritmi).mp3'
import QizlarReqse from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/19. QIZLAR RƏQSİ (Rəqs ritmi).mp3'
import Qaytagi2 from './Audio/7. Estrada miniatürləri, Rəqs ritimləri/20. QAYTAĞI 2 (Rəqs ritmi).mp3'

import Azerbaycan1Hisse from './Audio/8. Simfonik orkestr üçün əsərlər/1. AZƏRBAYCAN 1-Cİ HİSSƏ (SÜİTA).mp3'
import Azerbaycan2Hisse from './Audio/8. Simfonik orkestr üçün əsərlər/2. AZƏRBAYCAN 2-Cİ HİSSƏ (SÜİTA).mp3'
import Azerbaycan3Hisse from './Audio/8. Simfonik orkestr üçün əsərlər/3. AZƏRBAYCAN 3-CÜ HİSSƏ (SÜİTA).mp3'
import DuyguCaglari1 from './Audio/8. Simfonik orkestr üçün əsərlər/4. DUYĞU ÇALARLARI 1-Cİ HİSSƏ (PYES).mp3'
import DuyguCaglari2 from './Audio/8. Simfonik orkestr üçün əsərlər/5. DUYĞU ÇALARLARI 2-Cİ HİSSƏ (PYES).mp3'
import DuyguCaglari3 from './Audio/8. Simfonik orkestr üçün əsərlər/6. DUYĞU ÇALARLARI 3-CÜ HİSSƏ (PYES).mp3'
import DuyguCaglari4 from './Audio/8. Simfonik orkestr üçün əsərlər/7. DUYĞU ÇALARLARI 4-CÜ HİSSƏ (PYES).mp3'
import Denize from './Audio/8. Simfonik orkestr üçün əsərlər/8. DƏNİZƏ.mp3'
import Qatar from './Audio/8. Simfonik orkestr üçün əsərlər/9. QATAR.mp3'
import Shenlik from './Audio/8. Simfonik orkestr üçün əsərlər/10. ŞƏNLİK.mp3'
import TebietinOyanisi from './Audio/8. Simfonik orkestr üçün əsərlər/11. TƏBİƏTİN OYANIŞI (SÜİTA).mp3'
import DaglarSefeqi from './Audio/8. Simfonik orkestr üçün əsərlər/12. DAĞLAR ŞƏFƏQİ (SÜİTA).mp3'
import SheherinNegmesi from './Audio/8. Simfonik orkestr üçün əsərlər/13. ŞƏHƏRİN SƏHƏR NƏĞMƏSİ (SÜİTA).mp3'
import Nesimi1 from './Audio/8. Simfonik orkestr üçün əsərlər/14. NƏSİMİ 1-Cİ HİSSƏ (DRAM).mp3'
import Nesimi2 from './Audio/8. Simfonik orkestr üçün əsərlər/15. NƏSİMİ 2-Cİ HİSSƏ (DRAM).mp3'
import Nesimi3 from './Audio/8. Simfonik orkestr üçün əsərlər/16. NƏSİMİ 3-CÜ HİSSƏ (DRAM).mp3'
import SeherNegmesi2 from './Audio/8. Simfonik orkestr üçün əsərlər/17. SƏHƏR NƏĞMƏSİ.mp3'
import QaytagiSuita from './Audio/8. Simfonik orkestr üçün əsərlər/18. QAYTAĞI (SÜİTA).mp3'

import Giris from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/1. GİRİŞ.mp3'
import Xedice
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/2. XƏDİCƏ VƏ RƏXŞƏNDƏNİN DUETİ (1-ci pərdə).mp3'
import Nadir
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/3. NADİR VƏ PƏRVANƏNİN DUETİ (1-ci pərdə).mp3'
import NadirinHekayesi
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/4. NADİRİN MAHNI HEKAYƏSİ (1-ci pərdə).mp3'
import PervaneninAriyasi
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/5. PƏRVANƏNİN ARİYASI - Pərvanənin ariyası (1-ci pərdə).mp3'
import QizlarinXoru
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/6. QIZLARIN XORU (1-ci pərdə).mp3'
import TrioPervane
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/7. TRİO - PƏRVANƏ, KAMRAN, RƏXŞƏNDƏ (1-ci pərdə).mp3'
import MirismaylinYurusu
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/8. MİRİSMAYILIN YÜRÜŞÜ (1-ci pərdə).mp3'
import Yalli
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/9. YALLI (xor və balet) - (2-ci pərdə).mp3'
import Kvintet
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/10. KVİNTET TÖHFƏ VƏ 4 OĞLAN (2-ci pərdə).mp3'
import IlkBahar2
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/11. İLK BAHAR - NADİRİN LİRİK MAHNISI (2-ci pərdə).mp3'
import NadirPervane
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/12. NADİR, PƏRVANƏ VƏ QADIN XORU (2-ci pərdə).mp3'
import TelebelerMahnisi
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/13. TƏLƏBƏLƏR MAHNISI - TÖHFƏ VƏ XOR (2-ci pərdə).mp3'
import SakirVeTohfe
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/14. ŞAKİR VƏ TÖHFƏ DUETİ (2-ci pərdə).mp3'
import Kvartet
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/15. KVARTET - ŞAKİR, NADİR, EMİN, KAMRAN (3-cü pərdə).mp3'
import MirIsmayilinMahnisi
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/16. MİRİSMAYILIN MAHNISI (3-cü pərdə).mp3'
import SakirinMahnisi
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/17. ŞAKİRİN MAHNISI (3-cü pərdə).mp3'
import XediceVeRexsend
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/18. XƏDİCƏ VƏ RƏXŞƏNDƏNİN DUETİ (3-cü pərdə).mp3'
import PervaneninAriozusu
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/19. PƏRVANƏNİN ARİOZOSU (3-cü pərdə).mp3'
import SakirVeTohfe3
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/20. ŞAKİR VƏ TÖHFƏNİN DUETİ (3-cü pərdə).mp3'
import BadamliMahnisi
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/21. BADAMLI MAHNISI (3-cü pərdə).mp3'
import FinalXoru
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/22. FİNAL XORU VƏ İŞTİRAK EDƏNLƏR (3-cü pərdə).mp3'
import FortepianoVeFlyeta
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/23. FORTEPİANO VƏ FLEYTA ÜÇÜN 2 PYES.mp3'
import Fortepiano
    from './Audio/9. Qızılaxtaranlar - Musiqi komediya, Fortepiano musiqisi/24. FORTEPİANO ÜÇÜN ƏSƏRLƏR.mp3'

import Vokaliz from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/1. VOKALİZ (Caz improvizasiya).mp3'
import Yellencek
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/2. YELLƏNCƏK (Caz improvizasiya).mp3'
import YolCaz from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/3. YOL (Caz improvizasiya).mp3'
import QizilUzukCaz
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/4. QIZIL ÜZÜK (Caz improvizasiya).mp3'
import YollarCaz from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/5. YOLLAR (Caz improvizasiya).mp3'
import ZibeydeCaz from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/6. ZİBEYDƏ (Caz improvizasiya).mp3'
import Pyes1 from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/7. PYES 1 (Caz improvizasiya).mp3'
import Pyes2 from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/8. PYES 2 (Caz improvizasiya).mp3'
import Pyes3 from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/9. PYES 3 (Caz improvizasiya).mp3'
import Improvizasiya
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/10. İMPROVİZASİYA (Bəstəkarın royalda ifaları).mp3'
import Vals
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/11. VALS (Bəstəkarın royalda ifaları).mp3'
import BakiHaqqqindaRoyalMahni
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/12. BAKI HAQQINDA MAHNI (Bəstəkarın royalda ifaları).mp3'
import DenizleSohbet
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/13. DƏNİZLƏ SÖHBƏTİ (Bəstəkarın royalda ifaları).mp3'
import RoyalXatireler
    from './Audio/10. Caz improvizasiyalar, Bəstəkarın royalda ifaları/14. XATİRƏLƏR (Bəstəkarın royalda ifaları).mp3'

const App = () => {
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'az')
    const [activeFolder, setActiveFolder] = useState(null)
    const [activeMusic, setActiveMusic] = useState(null)

    const logos = {
        az: LogoAz
    }

    const childrenElement = (name, sound, author, songer, length) => {
        return {name, sound, author, songer, length}
    }

    const sounds = [
        {
            name: 'Mənim Azərbaycanım (Vətən haqqında mahnılar)',
            children: [
                childrenElement('GÖZƏLDİR VƏTƏNİN YAŞIL DÜZLƏRİ', GozeldirVetenin, 'Z.Cabbarzadənindir', 'Bülbül', '02:52'),
                childrenElement('VƏTƏNİM DEDİM', VetenimDedim, 'Z.Cabbarzadənindir', 'Bülbül', '02:29'),
                childrenElement('NEFTÇİ MAHNISI', Neftci, 'M.Svetlovundur', 'Rəşid Behbudov', '03:31'),
                childrenElement('CAN VƏTƏNİM', CanVetenim, null, 'Rauf Atakişiyev', '03:23'),
                childrenElement('SEVİRƏM SƏNİ, AZƏRBAYCAN', SeviremSeni, 'S. Vurğunundur', 'İdris Mehdiyev', '1:48'),
                childrenElement('AZƏRBAYCANA GƏLSİN', AzerbaycanaGelsin, 'S.Rüstəmindir', 'Şövkət Ələkbərova', '04:03'),
                childrenElement('EY SEVİNCİM, BAKI', EySevincim, null, 'Rəşid Behbudov', '03:31'),
                childrenElement('BU ELLƏRDƏ', BuEllerde, null, 'Mirzə Babayev', '01:51'),
                childrenElement('AXTARAN TAPAR', AxtaranTapar, null, 'Anatollu Qəniyev', '04:06'),
                childrenElement('ŞİRVAN GÖZƏLİ', SirvanGozeli, null, 'Xor və solistlər', '03:20'),
                childrenElement('ABŞERON MAYAKI', AbseronMayaki, null, 'Rauf Atakişiyev', '04:21'),
                childrenElement('NAXÇIVANIM', Naxcivanim, 'Mirzə Babayev', 'Mirzə Babayev', '02:27'),
                childrenElement('ANA KÜR', AnaKur, 'T.Elçinindir', 'Gülağa Məmmədov', '02:45'),
                childrenElement('BADAMLI', Badamli, 'Z.Cabbarzadənindir', 'Rəşid Behbudov', '02:12'),
                childrenElement('BAKILIYAM MƏN', Bakiliyam, 'N.Kəsəmənlinindir', 'Akif İslamzadə', '02:49'),
                childrenElement('ZİYAFƏT', Ziyafet, null, 'Rəşid Behbudov', '03:27'),
                childrenElement('VƏTƏN HAQQINDA MAHNI', VetenHaqqinda, 'R.Rzanındır', 'Rəşid Behbudov', '03:46'),
            ]
        },
        {
            name: 'MƏHƏBBƏT HAQQINDA MAHNILAR',
            children: [
                childrenElement('SƏNƏ DƏ QALMAZ', SenedeQalmaz, 'R.Rzanındır', 'R.Rzanındır'),
                childrenElement('SEVDİYİM QIZ', SevdiyimQiz, 'T.Elçinindir', 'Şövkət Ələkbərova'),
                childrenElement('CAVANAM MƏN', CavanamMen, null, 'Mirzə Babayev'),
                childrenElement('MƏN KÖVRƏLMİŞƏM', MenKovrelmisem, null, 'Şövkət Ələkbərova'),
                childrenElement('GƏL BƏRİ', GelBeri, 'Z.Cabbarzadənindir', 'Lütfiyar İmanov'),
                childrenElement('İLK BAHAR', IlkBahar, 'Z.Cabbarzadənindir', 'Şövkət Ələkbərova'),
                childrenElement('NAR GÜLÜ', NarGulu, null, 'Gülağa Məmmədov'),
                childrenElement('BAHAR GƏLİR', BaharGelir, null, 'Rəşid Behbudov'),
                childrenElement('KİPRİKLƏRİN KÖLGƏSİ', KipirklerinKolgesi, 'F.Qocanındır', 'Rauf Adıgözəlov'),
                childrenElement('YOL AYRICINDA', YolAyricinda, 'İ.Səfərlinindir', 'Şövkət Ələkbərova'),
                childrenElement('MƏNİM QIZIM', MenimQizim, 'Ş.Abbasovundur', 'Gülağa Məmmədov'),
                childrenElement('SƏN MƏNİM, MƏN SƏNİN', SenMenim, 'Z.Cabbarzadənindir', 'Şövkət Ələkbərova'),
                childrenElement('SEVİRƏM SƏNİ', SeviremSeni2, ' V.Quryanındır', 'Mirzə Babayev'),
                childrenElement('PƏRİŞAN OLDUĞUM ANLAR', PerisanOldugumAnlar, 'İ.Səfərlinindir', 'Şövkət Ələkbərova'),
                childrenElement('MƏHƏBBƏT QOCALMAZ', MehebbetQocalmaz, null, 'Oqtay Ağayev'),
                childrenElement('AXŞAM GÖRÜŞLƏRİ', AxsamGorusleri, 'R.Rzanındır', 'Şövkət Ələkbərova'),
                childrenElement('BƏXTƏVƏR OLDUM', BexteverOldum, 'Ə.Xəqaninindir', 'Azər Zeynalov'),
                childrenElement('ETİBAR', Etibar, 'F.Bağırzadənindir', 'Rauf Adıgözəlov'),
                childrenElement('TEZ GƏL', TezGel, 'Z.Cabbarzadənindir', 'Şövkət Ələkbərova'),
                childrenElement('SƏHƏR NƏĞMƏSİ', SeherNegmesi, 'İ.Səfərlinindir', 'Rəşid Behbudov'),
                childrenElement('TOY MAHNISI', ToyMahnisi, 'Ə.Əlibəylinindir', '"Qaya" kvarteti'),
            ]
        },
        {
            name: 'RUS DİLİNDƏ MAHNILAR',
            children: [
                childrenElement('ДОБРОЕ УТРО, МОЙ АЗЕРБАЙДЖАН', Dobroe, 'İ.Şaferanındır', 'Müslüm Maqomayev'),
                childrenElement('ОСЕНЬ', OSEN, 'T.Korşilovundur', 'Mayya Kristalinskaya'),
                childrenElement('У ДЕВИЧЬЕЙ БАШНИ', Devicey, 'P.Simonovundur', 'ДЕВИЧЬЕЙ'),
                childrenElement('С КЕМ СРАВНИТЬ', Sravnit, 'R.Simonov və P.Vladimirskinindir', 'Rəşid Behbudov'),
                childrenElement('КОГДА ЦВЕТЕТ АКАЦИЯ', Aksiya, 'M.Vişnyakovanındır', 'Rauf Atakişiyev'),
                childrenElement('В ДОБРЫЙ ПУТЬ', Dobriy, 'N.Dobronravovundur', 'Cavanşir Əliyev'),
                childrenElement('НОЧНАЯ СЕРЕНАДА', Nocnaya, 'НОЧНАЯ СЕРЕНАДА', 'Rauf Atakişiyev'),
                childrenElement('ВСЕГДА С ТОБОЙ', Vseqda, 'A.Plavnikindir', 'İosif Kobzon'),
                childrenElement('ЗАРЯ НАД КАСПИЕМ', Zarya, 'M.Svetlovundur', 'Rəşid Behbudov'),
                childrenElement('ЗУЛЕЙХА', Zuleyxa, 'Xalqındır', 'Rəşid Behbudov'),
                childrenElement('С ТОБОЙ', Tobiy, 'P.Simonovundur', 'Rauf Atakişiyev'),
                childrenElement('Я ЛЮБЛЮ ТЕБЯ', Lyubli, 'V.Quryanındır', 'Lyudmila İsayeva'),
                childrenElement('ЗОЛОТО ВЕТВИСТОЕ', Vetvistoe, 'A.Jarovundur', 'Rəşid Behbudov'),
                childrenElement('АЗЕРБАЙДЖАН', Azerbaycan, null, 'Rəşid Behbudov'),
                childrenElement('НЕ НАДО', Nado, null, 'Müslüm Maqomayev'),
                childrenElement('ЛИРИЧЕСКАЯ ПЕСНЯ', Lireceskaya, 'V.Vinnikovundur', 'Cavanşir Əliyev'),
                childrenElement('СЧАСТЛИВЫЙ НАПЕВ', Scastliviy, 'O.Hacıqasımovundur', '"Qaya" kvarteti'),
                childrenElement('РОДИМЫЙ КРАЙ-АЗЕРБАЙДЖАН', Rodimis, 'P.Simonovundur', 'Oqtay Ağayev'),
                childrenElement('МОЙ БАКУ', BakuSong, null, 'Rəşid Behbudov'),
                childrenElement('ДЕВУШКИ БАКУ', Devuski, 'P.Simonovundur', '"Qaya" kvarteti'),
            ]
        },
        {
            name: 'KİNOFİLMLƏRDƏN MAHNILAR (1-ci hissə)',
            children: [
                childrenElement('SEVGİ VALSI - BƏXTİYAR (1955)', SevgiValsi, 'Ə.Əlibəylinindir', 'Azər Zeynalov'),
                childrenElement('AZƏRBAYCAN - BƏXTİYAR (1955)', Azerbaycan_az, 'Ə.Əlibəylinindir', 'Azər Zeynalov'),
                childrenElement('ZİBEYDƏ - BƏXTİYAR (1955)', Zibeyde, 'Ə.Əlibəylinindir', 'Rəşid Behbudov'),
                childrenElement('BAKI NƏĞMƏSİ - BƏXTİYAR (1955)', BakiNegmesi, 'Ə.Əlibəylinindir', 'Rəşid Behbudov'),
                childrenElement('DOSTLUQ MAHNISI - BƏXTİYAR (1955)', DostluqMahnisi, 'Ə.Əlibəylinindir', 'Rəşid Behbudov'),
                childrenElement('SEVGİLİM - BƏXTİYAR (1955)', Sevgilim, 'Ə.Əlibəylinindir', 'Rəşid Behbudov'),
                childrenElement('TANQO - BƏXTİYAR (1955)', Tanqo, 'Ə.Əlibəylinindir', 'Rəşid Behbudov'),
                childrenElement('QIZIL ÜZÜK - BƏXTİYAR (1955)', QizilUzuk, 'R.Rzanındır', 'Rəşid Behbudov'),
                childrenElement('ARZULAR - GÖRÜŞ (1955)', Aruzlar, 'Z.Cabbarzadənindir', 'Şəfiqə Mustafayeva və Lütfiyar İmanov'),
                childrenElement('AXŞAM MAHNISI - GÖRÜŞ (1955)', AxsamMahnisi, 'Z.Cabbarzadənindir', 'Şövkət Ələkbərova'),
                childrenElement('QIZLARIN MAHNISI - QIZMAR GÜNƏŞ ALTINDA (1957)', QizlarinMahnisi, 'Ə.Əlibəylinindir', 'Nərminə Məmmədova'),
                childrenElement('LAYLAY - ÖGEY ANA (1958)', Laylay, 'Xalqındır', 'Şövkət Ələkbərova'),
                childrenElement('KƏNDİMİZ - ÖGEY ANA (1958)', Kendimiz, 'Z.Cabbarzadənindir', 'Uşaq xoru'),
                childrenElement('BAĞÇAMIZ - ÖGEY ANA (1958)', Bagcamiz, 'Z.Cabbarzadənindir', 'Uşaq xoru'),
                childrenElement('ƏZİZ SURƏT - ONU BAĞIŞLAMAQ OLARMI (1959)', EzizSuret, 'Z.Cabbarzadənindir', 'Rəşid Behbudov'),
                childrenElement('QƏMGİN MAHNI - ONU BAĞIŞLAMAQ OLARMI (1959)', QemginMahni, 'Z.Cabbarzadənindir', 'Şövkət Ələkbərova'),
                childrenElement('SEVİNC MAHNISI - ONU BAĞIŞLAMAQ OLARMI (1959)', SevincMahnisi, 'Z.Cabbarzadənindir', 'Firəngiz Əhmədova'),
                childrenElement('BAHAR MAHNISI - ONU BAĞIŞLAMAQ OLARMI (1959)', BaharMahnisi, 'Z.Cabbarzadənindir', 'Firəngiz Əhmədova'),
                childrenElement('BAKI HAQQINDA MAHNI - TELEFONÇU QIZ (1962)', BakiHaqqindaMahni, 'Z.Cabbarzadənindir', 'Rəşid Behbudov'),
                childrenElement('GƏLMƏDİ - TELEFONÇU QIZ (1962)', Gelmedi, 'Z.Cabbarzadənindir', 'Mirzə Babayev'),
                childrenElement('LİRİK MAHNI - MÖCÜZƏLƏR ADASI (1963)', LirikMahni, 'N.Xəzrinindir', 'Anatollu Qəniyev'),
                childrenElement('COŞ DƏNİZİM - MÖCÜZƏLƏR ADASI (1963)', CosDenizim, 'COŞ DƏNİZİM - MÖCÜZƏLƏR ADASI (1963)', '"Qaya" kvarteti'),
                childrenElement('VOKALİZ - MÖCÜZƏLƏR ADASI (1963)', Vokaliriz, null, 'Firəngiz Əhmədova'),
                childrenElement('YOX, YOX, YOX - SƏN NİYƏ SUSURSAN (1966)', YoxYoxYox, 'Z.Cabbarzadənindir', 'Gülağa Məmmədov'),
                childrenElement('NARGİLƏ - SƏN NİYƏ SUSURSAN (1966)', Nargile, 'Z.Cabbarzadənindir', 'Gülağa Məmmədov və xor'),
                childrenElement('KİMSƏN SƏN - O QIZI TAPIN (1970)', KimsenSen, 'V.Səmədoğlunundur', 'Oqtay Ağayev'),
                childrenElement('DƏRVİŞLƏRİN MAHNISI - NƏSİMİ (1973)', DervislerinMahnisi, 'Nəsiminindir', 'Gülağa Məmmədov'),
                childrenElement('SÖYLƏDİ YOX, YOX - NƏSİMİ (1973)', SoylediYoxYox, 'Aşıq Əmrahındır', 'Zeynəb Xanlarova'),
                childrenElement('MAHNI - DƏRVİŞ PARİSİ PARTLADIR (1976)', DervisParisiPatladir, null, 'Sergey Yurski'),
            ]
        },
        {
            name: 'KİNOFİLMLƏRDƏN MAHNILAR (2-ci hissə)',
            children: [
                childrenElement('KABABÇININ MAHNISI - ŞİR EVDƏN GETDİ (1977)', KababcininMahnisi, 'Y.Yakovlev və A.Axundovanındır', 'Mirzə Babayev'),
                childrenElement('LİRİK MAHNI - QAYNANA (1978)', QaynanaLirikMahni, 'F.Qocanındır', 'Flora Kərimova'),
                childrenElement('MƏHƏBBƏT ÇIRAĞI - QAYNANA (1978)', MehebbetCagi, 'F.Qocanındır', 'F.Qocanındır'),
                childrenElement('MEHRİBAN AİLƏYİK - QAYNANA (1978)', MehribanAileyik, 'F.Qocanındır', 'Akif İslamzadə, Yalçın Rzazadə və Flora Kərimova'),
                childrenElement('GƏLİNİM - QAYNANA (1978)', GelinimQaynana, 'F.Qocanındır', 'Nəsibə Zeynalova'),
                childrenElement('TƏZƏ GƏLİN - QAYNANA (1978)', TezeGelin, 'F.Qocanındır', 'Nəsibə Zeynalova və Yalçın Rzazadə'),
                childrenElement('LAYLAY - NƏĞMƏKAR TORPAQ (1981)', NegmekarTorpaq, 'Xalqındır', 'Xuraman Qasımova'),
                childrenElement('QIZ ƏLLƏRİ - NƏĞMƏKAR TORPAQ (1981)', QizElleri, 'V.Səmədoğlunundur', 'Akif İslamzadə'),
                childrenElement('YOLLAR - ƏZABLI YOLLAR (1982)', Yollar, 'R.Rövşənindir', 'Akif İslamzadə'),
                childrenElement('LAYLAY - ƏZABLI YOLLAR (1982)', LaylayEzabliYollar, 'R.Rövşənindir', 'Xuraman Qasımova'),
                childrenElement('QARDAŞLAR - ƏZABLI YOLLAR (1982)', Qardaslar, 'R.Rövşənindir', 'Akif İslamzadə'),
                childrenElement('DÖYÜŞ 1 - ƏZABLI YOLLAR (1982)', Doyus1, 'R.Rövşənindir', 'Lütfiyar İmanov'),
                childrenElement('DÖYÜŞ 2 - ƏZABLI YOLLAR (1982)', Doyus2, 'R.Rövşənindir', 'Lütfiyar İmanov və Flora Kərimova'),
                childrenElement('FİNAL - ƏZABLI YOLLAR (1982)', Final, 'R.Rövşənindir', 'Lütfiyar İmanov'),
                childrenElement('ДЕТИ - МУЗЫКА ПЛАНЕТЫ - MUSİQİ MÜƏLLİMİ (1983)', Deti, 'O.Hacıqasımovundur', 'Sərxan Sərxan'),
                childrenElement('ЛИСТ ЧИНАРА - MUSİQİ MÜƏLLİMİ (1983)', List, 'O.Hacıqasımovundur', 'Sərxan Sərxan'),
                childrenElement('ТЫ И Я - MUSİQİ MÜƏLLİMİ (1983)', SenMen, 'O.Hacıqasımovundur', 'Sərxan Sərxan'),
                childrenElement('ШАФИГА - MUSİQİ MÜƏLLİMİ (1983)', Sefiqa, 'O.Hacıqasımovundur', 'Sərxan Sərxan'),
                childrenElement('FAYTONÇU - MƏN BAKIDA YAŞAYIRAM (1986)', Faytoncu, 'N.Kəsəmənlinindir', 'Akif İslamzadə'),
                childrenElement('DARIXMA - XÜSUSİ VƏZİYYƏT (1986)', Darixma, 'R.Rövşənindir', 'Akif İslamzadə və Flora Kərimova'),
                childrenElement('VAXTINDA GƏLƏR - XÜSUSİ VƏZİYYƏT (1986)', VaxtindaGeler, 'R.Rövşənindir', 'Flora Kərimova'),
                childrenElement('FİNAL - XÜSUSİ VƏZİYYƏT (1986)', FinalXusisiEziyyet, 'R.Rövşənindir', 'Akif İslamzadə və Flora Kərimova'),
                childrenElement('NİYƏ GEDİRSƏN - XÜSUSİ VƏZİYYƏT (1986)', NiyeGedirsen, 'R.Rövşənindir', 'Akif İslamzadə'),
                childrenElement('AMAN ALLAH, SƏN KÖMƏYİM OL - NAKƏS (1991)', AmanAllah, 'F.Qocanındır', 'Mirzə Babayev'),
            ]
        },
        {
            name: 'KİNOFİLMLƏRDƏN İNSTRUMENTAL MUSİQİ',
            children: [
                childrenElement('PROLOQ - BƏXTİYAR (1955)', Bextiyar, null, null),
                childrenElement('PROLOQ - GÖRÜŞ (1955)', Gorus, null, null),
                childrenElement('MUSİQİ LÖVHƏSİ 1 - TELEFONÇU QIZ (1962)', MusiqiLovhesi1, null, null),
                childrenElement('MUSİQİ LÖVHƏSİ 2 - TELEFONÇU QIZ (1962)', MusiqiLovhesi2, null, null),
                childrenElement('MUSİQİ LÖVHƏSİ 3 - TELEFONÇU QIZ (1962)', MusiqiLovhesi3, null, null),
                childrenElement('MUSİQİ LÖVHƏSİ 4 - TELEFONÇU QIZ (1962)', MusiqiLovhesi4, null, null),
                childrenElement('1-Cİ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964)', Hisse1, null, null),
                childrenElement('2-Cİ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964)', Hisse2, null, null),
                childrenElement('3-CÜ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964)', Hisse3, null, null),
                childrenElement('4-CÜ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964)', Hisse4, null, null),
                childrenElement('5-Cİ HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964)', Hisse5, null, null),
                childrenElement('6-CI HİSSƏ - ƏSRLƏR BOYU BİR YERDƏ (1964)', Hisse6, null, null),
                childrenElement('YOL - SƏN NİYƏ SUSURSAN (1966)', Yol, null, null),
                childrenElement('NOKTYURN - ELMİN İŞIĞI (1969)', Noktyrun, null, null),
                childrenElement('DÜŞÜNCƏLƏR - O QIZI TAPIN (1970)', Dusunceler, null, null),
                childrenElement('PROLOQ - DƏRVİŞ PARİSİ PARTLADIR (1976)', ProloqDervis, null, null),
                childrenElement('PROLOQ - QAYNANA (1978)', ProloqQaynana, null, null),
                childrenElement('XATİRƏLƏR - ƏLAVƏ İZ (1981)', Xatireler, null, null),
                childrenElement('NOSTALJİ - ƏZABLI YOLLAR (1982)', Nostalji, null, null),
                childrenElement('PARAFRAZ - MUSİQİ MÜƏLLİMİ (1983)', Parafraz, null, null),
                childrenElement('HEYKƏLLƏR - MƏN BAKIDA YAŞAYIRAM (1986)', Hekayeler, null, null),
                childrenElement('İNTİZAR - SÜRƏYYA (1987)', Intizar, null, null),
                childrenElement('SEVGİ - SÜRƏYYA (1987)', Sevgi, null, null),
                childrenElement('STİLİSTİK VARİASİYALAR - ƏLAVƏ İZ (1991)', Stilistik, null, null),
                childrenElement('ÜFÜQ - ƏZABLI YOLLAR (1991)', Ufuq, null, null),
            ]
        },
        {
            name: 'ESTRADA MİNİATÜRLƏRİ, RƏQS RİTİMLƏRİ',
            children: [
                childrenElement('DOĞMA MELODİYALAR (Estrada miniatürləri)', DogmaMelodiylar, null, null),
                childrenElement('MUSİQİ LÖVHƏLƏRİ (Estrada miniatürləri)', MusiqiLovheleri, null, null),
                childrenElement('BAKI GECƏSİ (Estrada miniatürləri)', BakiGecesi, null, null),
                childrenElement('MEYDANDA (Estrada miniatürləri)', Meydanda, null, null),
                childrenElement('BAHAR MELODİYALARI (Estrada miniatürləri)', BaharMelodiylari, null, null),
                childrenElement('ESTRADA MİNİATÜRÜ 1', EstradaMiniaturu1, null, null),
                childrenElement('ESTRADA MİNİATÜRÜ 2', EstradaMiniaturu2, null, null),
                childrenElement('ŞƏHƏR (Estrada miniatürləri)', Sheher, null, null),
                childrenElement('POPURRİ (Estrada miniatürləri)', Poppuri, null, null),
                childrenElement('ÇİMƏRLİK VALSI (Rəqs ritmi)', CimerlikValsi, null, null),
                childrenElement('AZƏRBAYCAN RƏQSİ (Rəqs ritmi)', AzerbaycanReqsi, null, null),
                childrenElement('VALS 2 (Rəqs ritmi)', Vals2, null, null),
                childrenElement('SÖZSÜZ MAHNI (Rəqs ritmi)', SozsuzMahni, null, null),
                childrenElement('MƏZƏLİ RƏQS (Rəqs ritmi)', MezeliReqs, null, null),
                childrenElement('QAYTAĞI 1 (Rəqs ritmi)', Qaytagi, null, null),
                childrenElement('VALS 3 (Rəqs ritmi)', Vals3, null, null),
                childrenElement('RƏQS (Rəqs ritmi)', ReqsRitm, null, null),
                childrenElement('NAXÇIVANİ (Rəqs ritmi)', Naxcivani, null, null),
                childrenElement('QIZLAR RƏQSİ (Rəqs ritmi)', QizlarReqse, null, null),
                childrenElement('QAYTAĞI 2 (Rəqs ritmi)', Qaytagi2, null, null),
            ]
        },
        {
            name: 'SİMFONİK ORKESTR ÜÇÜN ƏSƏRLƏR',
            children: [
                childrenElement('AZƏRBAYCAN 1-Cİ HİSSƏ (SÜİTA)', Azerbaycan1Hisse, null, null),
                childrenElement('AZƏRBAYCAN 2-Cİ HİSSƏ (SÜİTA)', Azerbaycan2Hisse, null, null),
                childrenElement('AZƏRBAYCAN 3-CÜ HİSSƏ (SÜİTA)', Azerbaycan3Hisse, null, null),
                childrenElement('DUYĞU ÇALARLARI 1-Cİ HİSSƏ (PYES)', DuyguCaglari1, null, null),
                childrenElement('DUYĞU ÇALARLARI 2-Cİ HİSSƏ (PYES)', DuyguCaglari2, null, null),
                childrenElement('DUYĞU ÇALARLARI 3-CÜ HİSSƏ (PYES)', DuyguCaglari3, null, null),
                childrenElement('DUYĞU ÇALARLARI 4-CÜ HİSSƏ (PYES)', DuyguCaglari4, null, null),
                childrenElement('DƏNİZƏ', Denize, null, null),
                childrenElement('QATAR', Qatar, null, null),
                childrenElement('ŞƏNLİK', Shenlik, null, null),
                childrenElement('TƏBİƏTİN OYANIŞI (SÜİTA)', TebietinOyanisi, null, null),
                childrenElement('DAĞLAR ŞƏFƏQİ (SÜİTA)', DaglarSefeqi, null, null),
                childrenElement('ŞƏHƏRİN SƏHƏR NƏĞMƏSİ (SÜİTA)', SheherinNegmesi, null, null),
                childrenElement('NƏSİMİ 1-Cİ HİSSƏ (DRAM)', Nesimi1, null, null),
                childrenElement('NƏSİMİ 2-Cİ HİSSƏ (DRAM)', Nesimi2, null, null),
                childrenElement('NƏSİMİ 3-CÜ HİSSƏ (DRAM)', Nesimi3, null, null),
                childrenElement('SƏHƏR NƏĞMƏSİ', SeherNegmesi2, null, null),
                childrenElement('QAYTAĞI (SÜİTA)', QaytagiSuita, null, null),
            ]
        },
        {
            name: 'QIZILAXTARANLAR - MUSİQİ KOMEDİYA, FORTEPİANO MUSİQİSİ',
            children: [
                childrenElement('GİRİŞ', Giris, null, null),
                childrenElement('XƏDİCƏ VƏ RƏXŞƏNDƏNİN DUETİ (1-ci pərdə)', Xedice, null, null),
                childrenElement('NADİR VƏ PƏRVANƏNİN DUETİ (1-ci pərdə)', Nadir, null, null),
                childrenElement('NADİRİN MAHNI HEKAYƏSİ (1-ci pərdə)', NadirinHekayesi, null, null),
                childrenElement('PƏRVANƏNİN ARİYASI - Pərvanənin ariyası (1-ci pərdə)', PervaneninAriyasi, null, null),
                childrenElement('QIZLARIN XORU (1-ci pərdə)', QizlarinXoru, null, null),
                childrenElement('TRİO - PƏRVANƏ, KAMRAN, RƏXŞƏNDƏ (1-ci pərdə)', TrioPervane, null, null),
                childrenElement('MİRİSMAYILIN YÜRÜŞÜ (1-ci pərdə)', MirismaylinYurusu, null, null),
                childrenElement('MİRİSMAYILIN YÜRÜŞÜ (1-ci pərdə)', Yalli, null, null),
                childrenElement('KVİNTET TÖHFƏ VƏ 4 OĞLAN (2-ci pərdə)', Kvintet, null, null),
                childrenElement('İLK BAHAR - NADİRİN LİRİK MAHNISI (2-ci pərdə)', IlkBahar2, null, null),
                childrenElement('NADİR, PƏRVANƏ VƏ QADIN XORU (2-ci pərdə)', NadirPervane, null, null),
                childrenElement('NADİR, PƏRVANƏ VƏ QADIN XORU (2-ci pərdə)', TelebelerMahnisi, null, null),
                childrenElement('ŞAKİR VƏ TÖHFƏ DUETİ (2-ci pərdə)', SakirVeTohfe, null, null),
                childrenElement('KVARTET - ŞAKİR, NADİR, EMİN, KAMRAN (3-cü pərdə)', Kvartet, null, null),
                childrenElement('MİRİSMAYILIN MAHNISI (3-cü pərdə)', MirIsmayilinMahnisi, null, null),
                childrenElement('ŞAKİRİN MAHNISI (3-cü pərdə)', SakirinMahnisi, null, null),
                childrenElement('XƏDİCƏ VƏ RƏXŞƏNDƏNİN DUETİ (3-cü pərdə)', XediceVeRexsend, null, null),
                childrenElement('PƏRVANƏNİN ARİOZOSU (3-cü pərdə)', PervaneninAriozusu, null, null),
                childrenElement('ŞAKİR VƏ TÖHFƏNİN DUETİ (3-cü pərdə)', SakirVeTohfe3, null, null),
                childrenElement('BADAMLI MAHNISI (3-cü pərdə)', BadamliMahnisi, null, null),
                childrenElement('FİNAL XORU VƏ İŞTİRAK EDƏNLƏR (3-cü pərdə)', FinalXoru, null, null),
                childrenElement('FORTEPİANO VƏ FLEYTA ÜÇÜN 2 PYES', FortepianoVeFlyeta, null, null),
                childrenElement('FORTEPİANO ÜÇÜN ƏSƏRLƏR', Fortepiano, null, null),
            ]
        },
        {
            name: 'Caz improvizasiyalar, Bəstəkarın royalda ifaları',
            children: [
                childrenElement('VOKALİZ (Caz improvizasiya)', Vokaliz, null, null),
                childrenElement('YELLƏNCƏK (Caz improvizasiya)', Yellencek, null, null),
                childrenElement('YOL (Caz improvizasiya)', YolCaz, null, null),
                childrenElement('QIZIL ÜZÜK (Caz improvizasiya)', QizilUzukCaz, null, null),
                childrenElement('YOLLAR (Caz improvizasiya)', YollarCaz, null, null),
                childrenElement('ZİBEYDƏ (Caz improvizasiya)', ZibeydeCaz, null, null),
                childrenElement('PYES 1 (Caz improvizasiya)', Pyes1, null, null),
                childrenElement('PYES 2 (Caz improvizasiya)', Pyes2, null, null),
                childrenElement('PYES 3 (Caz improvizasiya)', Pyes3, null, null),
                childrenElement('İMPROVİZASİYA (Bəstəkarın royalda ifaları)', Improvizasiya, null, null),
                childrenElement('VALS (Bəstəkarın royalda ifaları)', Vals, null, null),
                childrenElement('BAKI HAQQINDA MAHNI (Bəstəkarın royalda ifaları)', BakiHaqqqindaRoyalMahni, null, null),
                childrenElement('DƏNİZLƏ SÖHBƏTİ (Bəstəkarın royalda ifaları)', DenizleSohbet, null, null),
                childrenElement('XATİRƏLƏR (Bəstəkarın royalda ifaları)', RoyalXatireler, null, null),
            ]
        }
    ]

    useEffect(() => {
        setActiveMusic(null)
        if (activeFolder) {
            document.querySelector('#musics').scrollIntoView({behavior: 'smooth'})
        }
    }, [activeFolder])

    return (
        <div className="container">
            <div className="header">
                <a href={`https://heydar-aliyev-foundation.org/az`} target="_blank">
                    <img src={Fond} alt="Logo" className="logo"/>
                </a>
                <a href={`https://culture.gov.az/${lang}`} target="_blank">
                    <img src={logos[lang]} alt="Logo" className="logo"/>
                </a>
            </div>
            <div className="main">
                <div className="info">
                    <div className="left">
                        <img src={TofiqQuliyev} alt="Tofiq Quliyev"/>
                    </div>
                    <div className="right" dangerouslySetInnerHTML={{__html: text[lang].description}}/>
                </div>
                <div className="music-box">
                    <div className="top">
                        <ul className="music-list">
                            {sounds.map((item, index) => (
                                <ListItem item={item} index={index} isActive={activeFolder} key={index}
                                          setIsActive={setActiveFolder}/>
                            ))}
                        </ul>
                        {activeFolder !== null ? (
                            <Musics musics={sounds[activeFolder].children}
                                    setActive={setActiveMusic} active={activeMusic}/>
                        ) : (
                            <div className="img">
                                <img src={Baku} alt="Baku"/>
                            </div>
                        )}
                    </div>
                    <div className="options">
                        <AudioPlayer
                            defaultCurrentTime={'00:00'}
                            defaultDuration={'00:00'}
                            src={activeMusic?.music?.sound}
                            showSkipControls={true}
                            onClickNext={() => {
                                if (sounds[activeFolder].children.length !== activeMusic?.index + 1) {
                                    setActiveMusic({
                                        index: activeMusic?.index + 1,
                                        music: sounds[activeFolder]?.children[activeMusic?.index + 1]
                                    })
                                }
                            }}
                            onClickPrevious={() => {
                                if (activeMusic?.index !== 0) {
                                    setActiveMusic({
                                        index: activeMusic?.index - 1,
                                        music: sounds[activeFolder]?.children[activeMusic?.index + -1]
                                    })
                                }
                            }}
                            showJumpControls={false}
                            header={<div>
                                {activeMusic?.music?.name && (
                                    <span>{activeMusic?.music?.name}, </span>
                                )}
                                {activeMusic?.music?.songer && (
                                    <span><span>İfaçı:</span> {activeMusic?.music?.songer}</span>
                                )}
                                {activeMusic?.music?.author && (
                                    <span><span>, Sözləri:</span> {activeMusic?.music?.author}</span>
                                )}
                            </div>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App;
